import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { APP_CONST } from "../config/const";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

import GamesIcon from "../assets/images/footer-icon/games.png";
import SportsIcon from "../assets/images/footer-icon/exchange.png";
import HomeIcon from "../assets/images/footer-icon/home.png";
import CashierIcon from "../assets/images/footer-icon/cashier.png";
import ProfileIcon from "../assets/images/footer-icon/user.png";
import ModalWrapper from "./ModalWrapper";

export default function BottomNav() {
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const { isAuth } = useSelector((state) => state.auth);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  // Close modal automatically after login
  useEffect(() => {
    if (isAuth && showAuthModals) {
      setShowAuthModals(false);
    }
  }, [isAuth, showAuthModals]);

  const footerPages = [
    "all-game",
    "allCasino",
    "spmsports/cricket",
    "home",
    "sports",
    "cashier",
    "support-customer",
    "/",
  ];

  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-lg-none">
        <ul>
          <li>
            <a
              className={activePage === "allCasino" ? "active" : ""}
              onClick={(e) => {
                if (!isAuth) {
                  e.preventDefault();
                  isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in");
                } else {
                  window.location.href = "/allCasino";
                }
              }}
              href="/allCasino"
            >
              <img src={GamesIcon} alt="Foot Nav Icon" />
              <span>Games</span>
            </a>
          </li>
          <li>
            <a
              className={activePage === "spmsports/cricket" ? "active" : ""}
              onClick={(e) => {
                if (!isAuth) {
                  e.preventDefault();
                  isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in");
                } else {
                  window.location.href = "/spmsports/cricket";
                }
              }}
              href="/spmsports/cricket"
            >
              <img src={SportsIcon} alt="Foot Nav Icon" />
              <span>Exchange</span>
            </a>
          </li>
          <li>
            <a
              className={
                activePage === "/" || activePage === "sports" ? "active" : ""
              }
              href="/sports"
            >
              <img src={HomeIcon} alt="home" />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a
              className={activePage === "cashier" ? "active" : ""}
              onClick={(e) => {
                if (!isAuth) {
                  e.preventDefault();
                  isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in");
                } else {
                  window.location.href = "/cashier";
                }
              }}
              href="/cashier"
            >
              <img src={CashierIcon} alt="cashier" />
              <span>Cashier</span>
            </a>
          </li>
          <li>
            <a
              className={activePage === "support-customer" ? "active" : ""}
              onClick={(e) => {
                if (!isAuth) {
                  e.preventDefault();
                  isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in");
                } else {
                  window.location.href = "/support-customer";
                }
              }}
              href="/support-customer"
            >
              <img src={ProfileIcon} alt="support" />
              <span>Support</span>
            </a>
          </li>
        </ul>
      </div>

      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={() => setShowAuthModals(false)}
        />
      )}
    </>
  );
}
