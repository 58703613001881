import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { providersData } from "../../../../lib/data/searchGamesData";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

const AllCasino = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderAfterLogin />
      <main className=" main">
        <Container>
          <div className="d-flex justify-content-between mb-2 container-fluid allcasino heading">
            <h4 className="">Casino games</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="providerPage container-fluid">
            {Array.isArray(providersData) &&
              providersData.map((item) => {
                return (
                  <a href={item.redirectUrl}>
                    {item.img}
                    <h5>{item.name}</h5>
                  </a>
                );
              })}
          </div>
        </Container>
      </main>

      <Footer />
    </>
  );
};

export default AllCasino;
