import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import PlayBtn from "../../../../assets/images/play-btn.png";

import { useSelector } from "react-redux";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import { slotsData } from "../../../../lib/data/slots.data";

const Slots = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="slots-section">
          <div className="heading">
            <h4>Slots</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>
          <div className="games-section mt-4">
            <ul>
              {Array.isArray(Object.values(slotsData)) &&
                Object.values(slotsData).map((item, index) => (
                  <li key={index}>
                    <a href={item.redirectUrl}>
                      <img src={item.imgUrl} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} alt="play" />
                      </div>
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Slots;
